<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.adi }} {{ mainData.soyadi }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="RAKİP TEZGAH" entityName="bm_rakipunite"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ad">Ad <span style="color:red">*</span></label>
								<InputText id="ad" type="text" v-model="mainData.ad" />
								<span v-if="v$.mainData.ad.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.ad.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="tezgahModeli">Tezgah Modeli</label>
								<InputText id="tezgahModeli" type="text" v-model="mainData.tezgahModeli" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="seriNo">Seri No</label>
								<InputText id="seriNo" type="text" v-model="mainData.seriNo" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="musteri" v-model="mainData.musteriName" ref="entity_musteri"
									label="Müşteri" entityName="account" nameField="name" :state="true"
									@itemSelected="musteriSelected = $event" @itemCleared="musteriSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="rakip" v-model="mainData.rakipName" ref="entity_rakip"
									label="Rakip" entityName="competitor" nameField="name"
									@itemSelected="rakipSelected = $event" @itemCleared="rakipSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="tezgahTipi">Tezgah Tipi</label>
								<Dropdown id="tezgahTipi" v-model="mainData.tezgahTipi" :options="SM_tezgahTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Tezgah Tipi" :showClear="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="rakipTezgahTipi">Rakip Tezgah Tipi</label>
								<Dropdown id="rakipTezgahTipi" v-model="mainData.rakipTezgahTipi" :options="SM_rakipTezgahTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Rakip Tezgah Tipi" :showClear="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="satinAlinmaTarihi">Satın Alınma Tarihi</label>
								<Calendar showButtonBar id="satinAlinmaTarihi" v-model="mainData.satinAlinmaTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="aciklama">Açıklama</label>
								<Textarea id="aciklama" rows="3" :autoResize="true" v-model="mainData.aciklama" placeholder="Açıklama" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10005,
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		let loader = this.$loading.show({
			container: this.$refs.smsContainer
		});

		try {
			debugger;

			if (this.firmaId != null) {
				try {
					debugger;
					this.crmService.GetEntityById({
					"entityName": "account",
					"entityId": this.firmaId,
					"columnNames": ["name", "accountid"]})
					.then(data_account => {
						if (data_account.jsonData != null) {
							debugger;

							this.mainData["musteriId"] = data_account.jsonData["accountid"];
							this.mainData["musteriName"] = data_account.jsonData["name"];

							let accountItem = { "Value": data_account.jsonData["accountid"], "Name": data_account.jsonData["name"] };

							this.$refs.entity_musteri.setDefaultData(accountItem);
							this.musteriSelected = accountItem;
						}
					})
					.catch(error => console.log(error));

				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				loader.hide();
			}
			
		} catch (error) {
			console.log(error);
			//this.$router.push('/');
		}
		finally {
			loader.hide();
		}
    },
	computed: {
		firmaId() {
			debugger;
			return this.$route.params.accountid;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		SM_tezgahTipi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tezgahtipi');
		},
		SM_rakipTezgahTipi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_rakipunitetipi', 'bool');
		},
		musteriSelected: {
			get: function () {
				if (this.mainData["musteriId"]) {
					return { "Value": this.mainData["musteriId"], "Name": this.mainData["musteriName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["musteriId"] = null;
					this.mainData["musteriName"] = null;
				}
				else {
					this.mainData["musteriId"] = newValue.Value;
					this.mainData["musteriName"] = newValue.Name;
				}
			}
		},
		rakipSelected: {
			get: function () {
				if (this.mainData["rakipId"]) {
					return { "Value": this.mainData["rakipId"], "Name": this.mainData["rakipName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["rakipId"] = null;
					this.mainData["rakipName"] = null;
				}
				else {
					this.mainData["rakipId"] = newValue.Value;
					this.mainData["rakipName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getRakipUniteCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
							else {
								setTimeout(() => {
									this.$router.push({ name: 'bm_rakipunite', params: { id: response.olusturulanId } });
								}, 2000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Rakip Tezgah');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				ad: {
					required : helpers.withMessage('Ad alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}
</style>
